export enum IconReferences {
  'arrow-right-outline' = './assets/img/icons/arrow-right-outline.svg',
  'bank' = './assets/img/icons/bank.svg',
  'brain' = './assets/img/icons/brain.svg',
  'check-circle' = './assets/img/icons/check-circle.svg',
  'checkbox' = './assets/img/icons/checkbox.svg',
  'dashboard' = './assets/img/icons/dashboard.svg',
  'doc' = './assets/img/icons/doc.svg',
  'document-scan' = './assets/img/icons/document-scan.svg',
  'download' = './assets/img/icons/download.svg',
  'draft' = './assets/img/icons/draft.svg',
  'edit' = './assets/img/icons/edit.svg',
  'icon-eye' = './assets/img/icons/icon-eye.svg',
  'icon-eye-lock' = './assets/img/icons/icon-eye-lock.svg',
  'icon-lock' = './assets/img/icons/icon-lock.svg',
  'icon-profile' = './assets/img/icons/icon-profile.svg',
  'key' = './assets/img/icons/key.svg',
  'key-white' = './assets/img/icons/key-white.svg',
  'left-arrow' = './assets/img/icons/left-arrow.svg',
  'list' = './assets/img/icons/list.svg',
  'list-circle' = './assets/img/icons/list-circle.svg',
  'marketing' = './assets/img/icons/marketing.svg',
  'no-list-white' = './assets/img/icons/no-list-white.svg',
  'no-report' = './assets/img/icons/no-report.svg',
  'report' = './assets/img/icons/report.svg',
  'right-arrow' = './assets/img/icons/right-arrow.svg',
  'search' = './assets/img/icons/search.svg',
  'search-bar' = './assets/img/icons/search-bar.svg',
  'search-file' = './assets/img/icons/search-file.svg',
  'take-decision' = './assets/img/icons/take-decision.svg',
  'trash' = './assets/img/icons/trash.svg',
  'upload' = './assets/img/icons/upload.svg',
  'vector' = './assets/img/icons/vector.svg',
  'website-scan' = './assets/img/icons/website-scan.svg',
  'media-scan' = './assets/img/icons/media-scan.svg',
  'youtube-scan' = './assets/img/icons/youtube-scan.svg',
  'redirect-arrow' = './assets/img/icons/redirect-arrow.svg',
  'justice' = './assets/img/icons/justice.svg',
  'vertical-key' = './assets/img/icons/vertical-key.svg',
  'exclamation' = './assets/img/icons/exclamation.svg',
  'patient_list' = './assets/img/icons/patient_list.svg',
  'invoice' = './assets/img/icons/invoice.svg',
  'remaining-time' = './assets/img/icons/remaining-time.svg',
  'folder_shared' = './assets/img/icons/folder-shared.svg',
  'share_off' = './assets/img/icons/share_off.svg',
  'restore' = './assets/img/icons/restore.svg',
  'organization' = './assets/img/icons/organization.svg',
  'arrow-forward' = './assets/img/icons/arrow-forward-ios.svg',
  'arrow-back' = './assets/img/icons/arrow-back-ios.svg',
  'resend' = './assets/img/icons/resend.svg'
}
