import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from '../../../services/profile.service';
import {AnalyticsService} from "../../../services/analytics.service";
import { Organization, OrganizationService } from '../../../services/organization.service';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SidebarService } from '../../../services/sidebar.service';

@Component({
  selector: 'side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit, OnDestroy {
  private orgUpdatesSubscription?: Subscription;

  user: any;
  organizations: Organization[] = [];
  showSpinner: boolean = false;
  selectedOrganizationControl = new FormControl();
  userIsOrgAdmin: boolean = false;

  constructor(
    public router: Router,
    readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly profileService: ProfileService,
    private readonly organizationService: OrganizationService,
    private readonly analyticsService: AnalyticsService,
    private readonly sidebarService: SidebarService
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
    this.orgUpdatesSubscription = this.sidebarService.updateSidebarSource$.subscribe(() => {
          this.refreshOrganizations();
    });
  }

  async logout(): Promise<void> {
    await this.authService.SignOut();
  }

  getUserInfo(): void {
    this.showSpinner = true;
    this.profileService
      .getUser()
      .then(async (response) => {
        this.user = response;
        this.organizations = await this.organizationService.getMyOrganizations();
        this.selectOrganization();
        this.updateIsUserOrgAdmin();
        this.showSpinner = false;
      })
      .catch((err) => {
        console.error(err);
        this.showSpinner = false;
      });
  }

  updateIsUserOrgAdmin() {
    this.authService.isOrganizationAdmin(this.selectedOrganizationControl.value.id).then(async (response) => {
      this.userIsOrgAdmin = response;
    });
  }

  async selectOrganization(): Promise<void> {
    const currentOrgId = this.authService.getCurrentOrganization();
    const currentOrg = this.organizations.find(org => org.id === currentOrgId);
    if (currentOrg) {
      this.selectedOrganizationControl.setValue(currentOrg);
      this.updateIsUserOrgAdmin();

      if (currentOrg.id) {
        const userRole = await this.organizationService.getUserRole(currentOrg.id, this.user.id)
        this.authService.setCurrentRole(userRole.role);
      }

      return;
    }

    this.selectedOrganizationControl.setValue(this.organizations[0]);
    this.authService.setCurrentOrganization(this.organizations[0].id!);
    if (this.organizations) {
      const userRole = await this.organizationService.getUserRole(this.organizations[0].id!, this.user.id)
      this.authService.setCurrentRole(userRole.role);
    }

    this.updateIsUserOrgAdmin();
  }

  async onClick(button: string): Promise<void> {
    await this.analyticsService.trackEvent(`Dashboard button clicked: ${button}`, { button: button })
  }

  onLogout(button: string): void {
    this.analyticsService.logout();
  }

  async openHelp(): Promise<void> {
    window.open('https://apexcomplianceprogram.com/help', '_blank');
  }

  handleSelection(selectedOrg: Organization): void {
    const currentId = this.authService.getCurrentOrganization()!;
    const selectedOrgId = selectedOrg.id!;
    if (this.router.url.includes(`/organizations/details/${currentId}`) ) {
      this.router.navigateByUrl(`/organizations/details/${selectedOrgId}`);
    } else {
      const currentUrl = this.router.url;
      this.router.navigate(['load'], { queryParams: { onlyLoad: true } }).then(() => {
        this.router.navigateByUrl(currentUrl);
        });
    }

    this.selectedOrganizationControl.setValue(selectedOrg);
    this.authService.setCurrentOrganization(selectedOrgId);
    this.updateIsUserOrgAdmin();
  }

  refreshOrganizations(): void {
    this.organizationService.getMyOrganizations().then((response) => {
      this.organizations = response;
      this.selectOrganization();
      this.updateIsUserOrgAdmin();
    });
  }

  ngOnDestroy(): void {
    this.orgUpdatesSubscription?.unsubscribe();
  }

}
